import React, { useEffect, useState } from 'react';
import FormInput from './component/FormInput';
import FormBox from './component/OnboardingFormBox';
import { liveEndpoint, url } from '../../components/home/Calculate';
import axios from 'axios';
import { formatSelectOptions, transformMaterialList } from '../../utils';
import ImageUpload from './component/ImageUpload';
import './styles.css'
import { FiDelete } from 'react-icons/fi';
import AddressAutocomplete from './component/GoogleAddress';
import Loader from '../../components/loader';
import SuccessModal from './component/SuccessModal';
import { Span } from '../../components/common/styles';
import ErrorModal from './component/ErrorModal';
import LoaderComponent from './component/LoaderComponent';
import MultiSelect from './component/multselect';
import { toast } from 'react-toastify';


interface Material {
  category: string;
  householdPrice: string;
  aggregatorPrice: string;
  industrialPrice: string;
}
interface HubLocation {
  name:string
  state: string;
  city?: string;
  address: string;
  landmark: string;
  serviceType:string;
  longitude:string;
  latitude:string
}

const OrganizationOnboarding: React.FC = () => {
  const [error, setError] = useState<string>("Oops! Something went wrong Please try again")
  const [ countriesList, setCountriesList] = useState<any>([])
  const [catList, setCatList] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [errMessage, setErrMessage] = useState<Record<string, string>>({});
  const [vehList, setVehList] = useState<any>([])
  const [vehicle, setVehicle] = useState<any>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [rcNumber, setRcNumber] = useState('')
  const [tagLine, setTagline] = useState('')
  const [country, setCountry] = useState('')
  const [countryList, setCountryList] = useState<any>([])
  const [stateList, setStateList] = useState<any>([])
  const [state, setState] = useState('')
  const [housePercent, setHousePercentage] = useState<number>()
  const [aggPercent, setAggPercentage] = useState<number>()
  const [industrialPercent, setIndustrialPercentage] = useState<number>()
  const [serviceType, setServiceType] = useState('')
  const [hasVehicle, setHasVehicle] = useState(true)
  const [priceAdjustment, setPriceAdjustment] = useState('')
  const [profileImage, setProfileImage] = useState<string>('');
  const [profileBanner, setProfileBanner] = useState<string>('');
  const [agreementDoc, setAgreementDoc] = useState<string>('');
  const [nin, setNin] = useState<string>('');
  const [materialList, setMaterialList] = useState<Material[]>([
    {
      category: '',
      householdPrice: '',
      aggregatorPrice: '',
      industrialPrice: '',
    },
  ]);
  const [hubLocation, setHubLocation] = useState<HubLocation[]>([
    {
      name:'',
        state: '',
        // city: '',
        address: '',
        landmark: '',
        serviceType:'pickup',
        longitude:'',
        latitude:''
    },
  ]);

  const handleImageUpload = async (
    imageData: any,
    fieldName: string,
    fileExtension: any
  ) => {
    setLoading(true);
  
    try {
      const res = await axios.post(
        "https://beta.pakam.ng/collector/api/v2/admin/organisation/uploadimage",
        {
          image: imageData,
          imageType: fileExtension,
        }
      );
  
      if (res?.data.success) {
        const fieldMap: Record<string, React.Dispatch<React.SetStateAction<string>>> = {
          profileImage: setProfileImage,
          profileBanner: setProfileBanner,
          agreement: setAgreementDoc,
          //nin: setNin,
        };
        console.log(res.data)
        // toast.success("File Uploaded Successfully!")
  
        if (fieldMap[fieldName]) {
          fieldMap[fieldName](res.data.data); // Update the corresponding state
          
        } else {
          console.warn(`Unhandled fieldName: ${fieldName}`);
        }
      } else {
        alert(`${fieldName} upload failed`);
      }
    } catch (error) {
      console.error(error);
      alert(`${fieldName} upload failed`);
    } finally {
      setLoading(false); // Ensure loading is stopped regardless of success or failure
    }
  };


  const handleValidation = (): boolean => {
    const errors: Record<string, string> = {};
    const phoneRegex = /^\+?[0-9]\d{1,14}$/; // Supports E.164 format (international)
   
    if (!name) errors.name = "Name field cannot be empty.";
    if (!phone) errors.phone = "Phone field cannot be empty.";
    if (!phoneRegex.test(phone)) errors.phone = "Phone number inputed is invalid";
    if (!tagLine) errors.tagLine = "Tag Line field cannot be empty.";
    if (!address) errors.address = "Address field cannot be empty.";
    if (!rcNumber) errors.rcNumber = "RC Number field cannot be empty.";
    if (!email) errors.email = "Email field cannot be empty.";
    if (!country) errors.country = "Kindly Select a Country ";
    if (!state) errors.state = "Kindly Select a State.";
    if (!priceAdjustment) errors.priceAdjustment = "Kindly select a pricing adjustment plan.";
    if (priceAdjustment === 'custom' && !housePercent) errors.housePercent = "Household percentage field cannot be empty.";
    if (priceAdjustment === 'custom' && !aggPercent) errors.aggPercent = "Aggregator percentage field cannot be empty.";
    if (priceAdjustment === 'custom' && !industrialPercent) errors.industrialPercent = "Industrial percentage field cannot be empty.";
    if (!agreementDoc) {
      errors.profile = "Ensure you've uploaded every required image and document.";
    }
    if ( hasVehicle && vehList.length === 0) {
      errors.vehicle = "Kindly add a vehicle type you possess ";
    }
    if (hubLocation.length < 1) {
      errors.hubLocation = "Add at least one hub location.";
    } else {
      hubLocation.forEach((hub, index) => {
        if (!hub.name) errors[`hubLocation[${index}].name`] = `Hub #${index + 1}: Name cannot be empty.`;
        if (!hub.state) errors[`hubLocation[${index}].state`] = `Hub #${index + 1}: State cannot be empty.`;
        if (!hub.address) errors[`hubLocation[${index}].address`] = `Hub #${index + 1}: Kindly select a corresponding address from the google suggestions.`;
        // if (!hub.address) errors[`hubLocation[${index}].address`] = `Hub #${index + 1}: Address cannot be empty.`;
        if (!hub.serviceType) errors[`hubLocation[${index}].serviceType`] = `Hub #${index + 1}: Service Type cannot be empty.`;
        if (!hub.longitude || !hub.latitude) errors[`hubLocation[${index}].longitude`] = `Hub #${index + 1}: Kindly select a corresponding address from the google suggestions.`;
        // if (!hub.latitude) errors[`hubLocation[${index}].latitude`] = `Hub #${index + 1}: Latitude cannot be empty.`;
      });
    }
  
    if (materialList.length < 1) {
      errors.materialList = "Add at least one material.";
    } else {
      materialList.forEach((material, index) => {
        if (!material.category) errors[`materialList[${index}].category`] = `Material #${index + 1}: Category cannot be empty.`;
        if (!material.householdPrice) errors[`materialList[${index}].householdPrice`] = `Material #${index + 1}: Household price cannot be empty.`;
        if (!material.aggregatorPrice) errors[`materialList[${index}].aggregatorPrice`] = `Material #${index + 1}: Aggregator price cannot be empty.`;
        if (!material.industrialPrice) errors[`materialList[${index}].industrialPrice`] = `Material #${index + 1}: Industrial price cannot be empty.`;
      });
    }
  
    setErrMessage(errors);
  
    return Object.keys(errors).length === 0;
  };

  const handleClearForm = () =>{
      setName('')
      setPhone('')
      setEmail('')
      setVehicle([])
      setCountry('')
      setRcNumber('')
      setTagline('')
      setState('')
      setProfileBanner('')
      setProfileImage('')
      setHousePercentage(0)
      setAggPercentage(0)
      setIndustrialPercentage(0)
      setPriceAdjustment('')
      setAgreementDoc('')
      setMaterialList(    [{
        category: '',
        householdPrice: '',
        aggregatorPrice: '',
        industrialPrice: '',
      }])
      setHubLocation([
        {
          name:'',
            state: '',
            // city: '',
            address: '',
            landmark: '',
            serviceType:'pickup',
            longitude:'',
            latitude:''
        }
      ])
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true)

    if (!handleValidation()) {
      setTimeout(()=>{
        setError(Object.values(errMessage)[0])
        setLoading(false)
        setOpenErrorModal(true)   
      }, 1000)

      return; 
    }

    const materialPayload = transformMaterialList(materialList)
    const payload ={
      "companyName":name,
      "email":email,
      "rcNo":rcNumber,
      "companyTag":tagLine,
      "phone":phone,
      "location":address,
      "country":country.toLowerCase(),
      "state":state.toLowerCase(),
      "householdCatPricing":materialPayload.householdCatPricing,
      "aggregatorCatPricing":materialPayload.aggregatorCatPricing,
      "industryCatPricing":materialPayload.industrialCatPricing,
      "householdDropoffPercentage":housePercent,
      "aggregatorDropoffPercentage":aggPercent,
      "industryDropoffPercentage":industrialPercent,
      "bannerUrl":profileBanner,
      "logo":profileImage,
      "hasLogisitcs":hasVehicle,
      "vechicleType":vehicle,
      "hubs":hubLocation,
      //"ninImage":nin,
    "aggreementImage":agreementDoc
    }

    try {
      const res = await axios.post(`${url}/collector/api/v2/organisation/create`, payload)
      console.log(res)
      if(res.status){
        setLoading(false)
        setOpenModal(true)
        handleClearForm()
      }else{
        setLoading(false)
        // setError(res.message)
        setOpenErrorModal(true)
      }
    } catch (error:any) {
      setError(error?.response?.data?.message.split('.')[1])
       setLoading(false)
        setOpenErrorModal(true)
    }

  }

  const fetchLocation= async() => {
      
    try {
      const res = await axios.get(`${url}/api/world/locations`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
          setCountriesList(res.data.data)
          const countries = formatSelectOptions(res.data.data, "name");
          setCountryList(countries)
      
    } catch (error) {
      console.log(error)
    }
  }
  const fetchVehicles= async() => {
      
    try {
      const res = await axios.get(`${url}/collector/api/v2/organisation/vechicletypes`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
          setVehList(res.data.data)
      
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    const selectedCountryData = countriesList.find((i:any)=>i.name === country)
    setStateList(formatSelectOptions(selectedCountryData?.states, "name"))
  }, [country])
  
  const fetchCatItems= async() => {
      
    try {
      const res = await axios.get(liveEndpoint, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

          const result = res?.data?.data
          const list_of_sub_cat = result?.map((item:any)=>item.items).flat()
          setCatList(formatSelectOptions(list_of_sub_cat, "name", "value"))
        
      
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(()=>{
    fetchCatItems()
    fetchLocation()
    fetchVehicles()
  }, [])

  const handleAddHubLocation = () => {
    setHubLocation((prev) => [
      ...prev,
      {
        name:"",
        state: '',
        // city: '',
        serviceType:'pickup',
        address: '',
        landmark: '',
        longitude:'',
        latitude:''
    }
    ]);
  };

  const handleAddressSelect = (address: string, coordinates: { lat: number; lng: number }, id:number) => {
    handleHubLocationChange(id, 'address', address)
    handleHubLocationChange(id, 'longitude', String(coordinates.lat))
    handleHubLocationChange(id, 'latitude', String(coordinates.lng))
  };

  const handleRemoveHubLocation = (index: number) => {
    setHubLocation((prev) => prev.filter((_, i) => i !== index));
  };


  const handleAddMaterial = () => {
    setMaterialList((prev) => [
      ...prev,
      { category: '', householdPrice: '', aggregatorPrice: '', industrialPrice:'' },
    ]);
  };

  const handleRemoveMaterial = (index: number) => {
    setMaterialList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleMaterialChange = (
    index: number,
    field: keyof Material,
    value: string
  ) => {
    setMaterialList((prev) => {
      const updatedMaterials = [...prev];
      updatedMaterials[index][field] = value;
      return updatedMaterials;
    });
  };

  const handleHubLocationChange = <T extends keyof HubLocation>(
    index: number,
    field: T,
    value: HubLocation[T] // Ensures value matches the type of the field
  ) => {
    setHubLocation((prev) => {
      const updatedHubLocations = [...prev];
      updatedHubLocations[index][field] = value; // TypeScript now knows this is safe
      return updatedHubLocations;
    });
  };
  
  return (
    <>
      <div className='onboarding-box'>
        <SuccessModal open={openModal} onClose={()=>setOpenModal(false)}/>
        <ErrorModal open={openErrorModal} message={error} onClose={()=>setOpenErrorModal(false)}/>
        <LoaderComponent open={loading} onClose={()=>setLoading(false)}/>

        <div className='banner relative flex flex-col items-center w-full justify-center'>
          {/* <img className='tag1 absolute lg:w-[200px] ' src="/img/tag.png" alt="" />
          <img className='tag2 absolute lg:w-[200px]' src="/img/tag.png" alt="" /> */}
          <p className='text-primary text-center font-[700] hidden md:block text-3xl'>Empowering Recycling Organizations, <br />One Connection at a Time</p>
          <p className='text-primary text-center font-[700] md:hidden text-3xl'>Empowering Recycling Organizations, One Connection at a Time</p>

          <span className='text-center font-medium pt-4'>Join Pakam to revolutionalize waste management, connect with eco-conscious users, and track your impact. Lets build a sustainable future together</span>
        </div>

        <div className="banner-tagline pt-8 pb-4">
          <h1 className='primary'>Get Started with Pakam</h1>
          <p style={{marginTop:'1rem'}}>To begin your onboarding process, please follow these simple steps:</p>

          <div className='grid grid-cols-1 lg:grid-cols-2'>
            <div>
              <h3>Step 1: Download and Sign Your Agreement Form</h3>
              <p style={{fontWeight:500}}>Download your agreement form <a href="/documents/Aggregator Agreement.pdf" style={{fontWeight:700, color:'#005A00'}} download>here</a> and sign it. <br/>
              This is a required step to complete your onboarding.</p>
            </div>
            
            <div>
              <h3>Step 2: Fill Out the Form Below</h3>
              <p style={{fontWeight:500}}>Once you've signed your agreement form, please fill out the form below and upload the signed document. We'll review your submission to complete your onboarding.</p>
            </div>

          </div>

          {/* {errMessage && <span>{errMessage}</span>} */}
        </div>

        <div className="px-[5rem] pt-[20rem]">
          <form onSubmit={handleSubmit} className='space-y-8'>
              {
                (errMessage.profile) && <span style={{color:'red'}}>{errMessage.profile}</span>
              }
            <FormBox title="Basic Information">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <FormInput
                compulsory
                  type="text"
                  label="Organization Name"
                  placeholder="ABC Waste Company"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={errMessage.name}

                />
                <FormInput
                  compulsory
                  type="text"
                  label="Phone Number"
                  placeholder="08011223344"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value.trim())}
                  error={errMessage.phone}
                />
                <FormInput
                  compulsory
                  type="text"
                  label="Contact Email"
                  placeholder="abc@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={errMessage.email}
                />
                
                <FormInput
                  compulsory
                  type="text"
                  label="Address"
                  placeholder="Enter your address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  error={errMessage.address}
                />
                <FormInput
                  compulsory
                  type="text"
                  label="RC Number"
                  placeholder="rc-10112"
                  value={rcNumber}
                  onChange={(e) => setRcNumber(e.target.value)}
                  error={errMessage.rcNumber}
                />


                <ImageUpload
                    compulsory
                    title="Upload Signed Agreement Form" 
                    width='350'
                    height='154'
                    type='document'
                    fieldName="agreement" 
                    onFileUpload={handleImageUpload}
        
                  />
                {/* <ImageUpload
                    compulsory
                    title="Upload NIN" 
                    width='350'
                    type='picture'
                    height='154'
                    fieldName="nin" 
                    onFileUpload={handleImageUpload}
        
                  /> */}
              </div>
            </FormBox>

            <FormBox title="Custom Details">
              <div className="grid grid-cols-1 md:grid-cols-2">
                <FormInput
                compulsory
                  type="text"
                  label="Tagline (Abbrevation)"
                  placeholder="ABC-WC"
                  value={tagLine}
                  onChange={(e) => setTagline(e.target.value)}
                  error={errMessage.tagLine}
                />              
              </div>

              <div className='grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-16'>
                  <ImageUpload
                    title="Upload Organisation's Logo" 
                    width='1000'
                    height='1000'
                    type='picture'
                    fieldName="profileImage" 
                    onFileUpload={handleImageUpload}
        
                  />
                  <ImageUpload 
                    title="Upload Organisation's Banner" 
                    width='350'
                    height='154'
                    type='picture'
                    fieldName="profileBanner" 
                    onFileUpload={handleImageUpload}
                  />

              </div>
            </FormBox>

            <FormBox title="Operational Coverage">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <FormInput
                  compulsory
                  type="select"
                  label="Country"
                  options={countryList}
                  placeholder="Select country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  error={errMessage.country}
                />
                <FormInput
                  compulsory
                  type="select"
                  label="State"
                  options={stateList}
                  placeholder="Select state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  error={errMessage.state}
                />
              </div>
            </FormBox>

            <FormBox title="Material and Pricing">
              {materialList.map((material, index) => (
                <div key={index}>
                  <p className='italic font-[600] text-[#35393f] mb-4'>Material {index + 1}</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <FormInput
                    compulsory
                      type="select"
                      label="Waste Category"
                      placeholder="Select Waste Category"
                      options={catList}
                      value={material.category}
                      onChange={(e) =>
                        handleMaterialChange(index, 'category', e.target.value)
                      }
                      error={errMessage?.[`materialList[${index}].category`]}
                    />
                    <FormInput
                    compulsory
                      type="text"
                      label="Price Per KG (Household)"
                      placeholder="100"
                      value={material.householdPrice}
                      onChange={(e) =>
                        handleMaterialChange(index, 'householdPrice', e.target.value)
                      }
                      error={errMessage?.[`materialList[${index}].householdPrice`]}
                    />
                    <FormInput
                    compulsory
                      type="text"
                      label="Price Per KG (Aggregator)"
                      placeholder="100"
                      value={material.aggregatorPrice}
                      onChange={(e) =>
                        handleMaterialChange(index, 'aggregatorPrice', e.target.value)
                      }
                      error={errMessage?.[`materialList[${index}].aggregatorPrice`]}
                    />
                    <FormInput
                    compulsory
                      type="text"
                      label="Price Per KG (Industrial)"
                      placeholder="100"
                      value={material.industrialPrice}
                      onChange={(e) =>
                        handleMaterialChange(index, 'industrialPrice', e.target.value)
                      }
                      error={errMessage?.[`materialList[${index}].industrialPrice`]}
                    />
                  </div>
                  {materialList.length > 1 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveMaterial(index)}
                      className="mt-2 text-red-600"
                    >
                      Remove Material
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddMaterial}
                className="mt-4 text-primary font-medium text-[12px] py-2 px-4 rounded"
              >
                Add Another Material +
              </button>
            </FormBox>
            <FormBox title="Collection Preferrence">

                <div className='py-6 gap-4'>
                  {hubLocation.map((location, index) => (
                    <div key={index}>
                      <div className='flex gap-3'>
                        <p className='italic font-[600] text-[#35393f] mb-4'>Hub Location {index + 1}</p>
                        {hubLocation.length > 1 && (
                          <button
                            type="button"
                            onClick={() => handleRemoveHubLocation(index)}
                            className="mt-2 text-red-600"
                          >
                            <FiDelete color='red'/>
                          </button>
                        )}
                      </div>


                      <div>
                        <p className='italic font-[600] text-[#35393f] mb-4'>Service Type</p>
                        <div className='flex flex-wrap gap-4'>
                              <FormInput
                                type='checkbox'
                                placeholder="Pick Up Service"
                                value={location.serviceType === 'pickup'}
                                onChange={(e)=>handleHubLocationChange(index, 'serviceType', 'pickup')}
                              />
                              <FormInput
                                type='checkbox'
                                placeholder="Drop-Off Service"
                                value={location.serviceType === 'dropoff'}
                                onChange={(e)=>handleHubLocationChange(index, 'serviceType', 'dropoff')}
                              />                    
                              <FormInput
                                type='checkbox'
                                placeholder="Both Services"
                                value={location.serviceType === 'both'}
                                onChange={(e)=>handleHubLocationChange(index, 'serviceType', 'both')}
                              />                    
                          </div>

                        </div>
                      
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        
                        <FormInput
                        compulsory
                          type="text"
                          label="Hub Name"
                          placeholder="ABC Lekki Hub"
                          value={location.name}
                          onChange={(e) =>
                            handleHubLocationChange(index, 'name', e.target.value)
                          }
                          error={errMessage?.[`hubLocation[${index}].name`]}
                        />
                        <FormInput
                          compulsory
                          type="select"
                          label="State"
                          placeholder="Select state"
                          options={stateList}
                          value={location.state}
                          onChange={(e) =>
                            handleHubLocationChange(index, 'state', e.target.value)
                          }
                          error={!country ? 'Kindly select your country of operation': !location.state ? 'Kindly select your state of operation':''}
                        />

                        <AddressAutocomplete ind={index} onSelectAddress={handleAddressSelect} />

                        <FormInput
                          type="text"
                          label="Landmark (Optional)"
                          placeholder="Enter landmark"
                          value={location.landmark}
                          onChange={(e) =>
                            handleHubLocationChange(index, 'landmark', e.target.value)
                          }
                          error={errMessage?.[`hubLocation[${index}].landmark`]}
                        />
                      </div>

                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={handleAddHubLocation}
                    className="mt-4 text-primary text-[12px] font-medium py-2 px-4 rounded"
                  >
                    Add Another Hub Location +
                  </button>                
                </div>

              <div>
                <p className='italic font-[600] text-[#35393f] mb-4'>Pricing Adjustment for Drop-Off:</p>
                {
                (errMessage.priceAdjustment) && <span style={{color:'red'}}>{errMessage.priceAdjustment}</span>
              }

                <div className='flex flex-wrap gap-4'>
                      <FormInput
                        type='checkbox'
                        placeholder="Use Pakam's suggested increase (50%)"
                        value={priceAdjustment === 'suggest'}
                        onChange={(e)=>{setPriceAdjustment('suggest'); setHousePercentage(50); setAggPercentage(50); setIndustrialPercentage(50)}}
                        
                      />
                      <FormInput
                        type='checkbox'
                        placeholder='Set Custom Percentage Increase'
                        value={priceAdjustment === 'custom'}
                        onChange={(e)=>setPriceAdjustment('custom')}
                      />                    
                    </div>

              </div>

              {
                priceAdjustment === 'custom' &&
                <div className='grid grid-cols-2 gap-6'>
                  <FormInput
                    type="text"
                    label="Household Drop-Off Percentage"
                    placeholder="Enter percentage"
                    value={housePercent}
                    onChange={(e) => setHousePercentage(Number(e.target.value))}
                  />              
                  <FormInput
                    type="text"
                    label="Aggregator Drop-Off Percentage"
                    placeholder="Enter percentage"
                    value={aggPercent}
                    onChange={(e) => setAggPercentage(Number(e.target.value))}
                  />              
                  <FormInput
                    type="text"
                    label="Industrial Drop-Off Percentage"
                    placeholder="Enter percentage"
                    value={industrialPercent}
                    onChange={(e) => setIndustrialPercentage(Number(e.target.value))}
                  />              
                </div>
              }



            </FormBox>

            <FormBox title="Logistics Capacity">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="block text-sm font-[500] text-[#35393F] mb-1">
                    Do you have your own logistic vehicles

                    <div className='flex gap-4'>
                      <FormInput
                        type='checkbox'
                        placeholder='Yes'
                        value={hasVehicle && true}
                        onChange={(e)=>setHasVehicle(true)}
                      />
                      <FormInput
                        type='checkbox'
                        placeholder='No'
                        value={!hasVehicle && true}
                        onChange={(e)=>setHasVehicle(false)}
                      />                    
                    </div>

                  </p>
                </div>

                {
                  hasVehicle &&
                  <MultiSelect
                  options={vehList}
                  onChange={setVehicle}
                  />
                //   <FormInput
                //   type="select"
                //   label="Type of Vehicle"
                //   placeholder="Select Vehicle"
                //   value={vehicle}
                //   options={vehList}
                //   onChange={(e) => setVehicle(e.target.value)}
                // />
                }

              </div>
            </FormBox>

          {

          }
          <div className='flex gap-4 items-center'>
            <button  type='submit' className='bg-primary text-white w-[200px] rounded-3xl p-2'>Submit</button>
            <button type='button' className='border-2 border-[#FF3E3E] text-[#FF3E3E] w-[200px] rounded-3xl p-2'>Cancel</button>          
          </div>


          </form>
        </div>
      </div>    
    </>

  );
};

export default OrganizationOnboarding;
