import { Modal, Box, Typography, Button } from '@mui/material';
import React from 'react';

interface SuccessModalProps {
  open: boolean; 
  onClose: () => void; 
  title?: string; // Optional title for the modal
  message?: string; // Optional message for the modal
}



const LoaderComponent: React.FC<SuccessModalProps> = ({ open, onClose, title = "Success", message = "Oops! Something went wrong Please try again" }) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="success-modal-title" aria-describedby="success-modal-description">
      <Box
        className=" rounded-lg p-6 shadow-lg"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          textAlign: 'center',
        }}
      >
        <div className='w-full h-full overflow-hidden flex justify-center items-center z-[21]'>
          <div style={{borderColor:'white'}} className='w-16 h-16 border-4  border-solid rounded-full animate-spin border-t-transparent'>

          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default LoaderComponent;


