import React, { useState, useRef, useEffect, useCallback } from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import FormInput from "./FormInput";
import ErrorModal from "./ErrorModal";

interface AddressAutocompleteProps {
  onSelectAddress: (address: string, coordinates: { lat: number; lng: number }, id: number) => void;
  ind: number;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ onSelectAddress, ind }) => {
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [error, setError] = useState<string>("");
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDJxHOd_rkPfI38KA129FsSSGI61n07aEU", 
    libraries: ["places"],
  });

  // Debounce function to delay execution of API call
  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Debounced version of address validation (Must be outside conditionals)
  const debouncedValidateAddress = useCallback(debounce((inputAddress: string) => {
    if (!inputAddress) return;

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: inputAddress }, (results, status) => {
      if (status === "OK" && results && results.length > 0) {
        setError(""); // Clear error if found
        setOpenErrorModal(false);
      } else {
        setError("Address not found on Google. Please select a valid address.");
        setOpenErrorModal(true);
      }
    });
  }, 1500), []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const handlePlaceChanged = () => {
    const autocomplete = autocompleteRef.current;

    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (!place || !place.geometry?.location || !place.formatted_address) {
        setError("Place details or geometry is missing on Google.");
        setOpenErrorModal(true);
      } else {
        const { formatted_address } = place;
        const location = place.geometry.location;

        setAddress(formatted_address);
        setError(""); // Clear any existing error
        setOpenErrorModal(false);
        onSelectAddress(formatted_address, { lat: location.lat(), lng: location.lng() }, ind);
      }
    }
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setAddress(value);
    debouncedValidateAddress(value); // Trigger debounced validation
  };

  return (
    <div>
      <ErrorModal open={openErrorModal} message={error} onClose={() => setOpenErrorModal(false)} />
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceChanged}
      >
        <FormInput
          compulsory
          type="text"
          label="Address"
          value={address}
          onChange={handleInputChange}
          placeholder="Enter your address"
          error={!address ? "Kindly input your address and select a corresponding suggestion" : error}
        />
      </Autocomplete>
    </div>
  );
};

export default AddressAutocomplete;
