import { Container } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'

export const url =  process.env.REACT_APP_LIVE_URL
 const stagingEnpoint = 'https://stagingapi.pakam.ng/api/category/all'
    export const liveEndpoint = `${url}/api/category/all`

interface Item {
    value: string;
    name: string;
    max: number;
    min: number;
  }
  

const Calculate = () => {    

    const [amount, setAmount] = useState<string>('')
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [open, setOpen] = useState<boolean>(false)
    const [data, setData] = useState<Item[]>([])
    const [new_data, setNewData] = useState<Item[]>([])
    const [selected, setSelected] =  useState<string>('')
    const [minPrice, setMinPrice] = useState<number | undefined>();
    const [maxPrice, setMaxPrice] = useState<number | undefined>();
    const menuRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
          if (menuRef.current && e.target instanceof Node) {
            if (!menuRef.current.contains(e.target)) {
              setOpen(false);
            }
          }
        };
    
        window.addEventListener('click', handleClickOutside);
    
        return () => {
          window.removeEventListener('click', handleClickOutside);
        };
      }, []);

    // const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     setSelected(event.target.value);
    //   };
    const handleChange = (value:string) => {
        setSelected(value);
      };

    const handleCalculate = () =>{
        const obj =  data?.find((i:any)=> i.name === selected)
        if(!amount){
            setAmount('1')
            setMaxPrice(obj?.max ? obj.max * 1 : undefined);
            setMinPrice(obj?.min ? obj.min * 1 : undefined);
        }else{
            setMaxPrice(obj?.max ? obj.max * parseInt(amount) : undefined);
            setMinPrice(obj?.min ? obj.min * parseInt(amount) : undefined);
        }
        
    }

    const fetchCatItems= async() => {
      
      try {
        const res = await axios.get(liveEndpoint, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

            const result = res?.data?.data
            

        if(result){
            const options = result.map((i:any)=>i.items)
            const catList = options.flat()
            setData(catList)
        }
        
      } catch (error) {
        console.log(error)
      }
    }



    useEffect(()=>{
        if(data){
            if(!searchQuery){
                setNewData(data)
            }else{
                const filteredData = data.filter((i)=>i.name.toLowerCase().includes(searchQuery))
                setNewData(filteredData)
            }
        }

       
    }, [searchQuery, data])

    useEffect(()=>{
        fetchCatItems()
    }, [])
  
  return (
        <div data-aos="fade-up"  data-aos-easing="ease-out"
        data-aos-duration="2500" className='flex flex-col pb-[6rem] md:pb-[4rem] lg:pb-[3rem] md:flex-row justify-center md:justify-between items-center w-full py-[3rem] px-[1rem] gap-8 md:gap-0 md:px-[2rem] lg:px-[8rem] bg-[#005A00] relative'>
            <div className='w-full md:w-1/2 h-full'>
                <div>
                    <h2 className='text-white text-[36px] font-[700] font-int text-center md:text-left  leading-[32px]'>Value your recyclables</h2>
                    <p className='text-white text-[14px] font-[500] text-center md:text-left font-int w-[95%] md:w-[80%] lg:w-[70%] mt-4'>You can get an estimate of the worth of your recyclables by selecting the material and the number of KG.</p>
                </div>

                <p className='text-white text-[12px] md:italic absolute bottom-10  text-center lg:text-left'>Every calculation is an estimate till a collector picks up your materials and weighs them.</p>

            </div>

            <div className='w-full flex flex-col justify-center  md:block  md:w-1/2'>
                <form action="" className='space-y-8 w-full'>

                    <div ref={menuRef} className='w-full relative'> 
                        <p className='font-Rale font-[400] text-[14px] mb-3 text-white'>Items</p>
                        <div onClick={()=>setOpen(true)} className='relative'>
                            <input  onFocus={()=>setOpen(true)} value={selected} placeholder='Select your recylable material' className='w-full rounded-lg py-4 px-2 md:px-4 outline-none placeholder:font-int placeholder:font-[300]'/>    
                            <img onClick={()=>setOpen(!open)} className={`absolute right-6 top-6 transition-all ease-in-out duration ${open? 'rotate-180' : ''}`} src="/svg/chevrondown.svg" alt="chevron" />                        
                        </div>


                        <div  className={`${open ? 'block' : 'hidden'} absolute w-full left-0 top-[6rem] bg-white border shadow-lg rounded-lg p-3`}>
                            <div> 
                                <div className='flex gap-2 border-[#F5F6F7] border rounded-lg p-2 items-center'>
                                    <div><img className='w-[16px] h-[16px]' src="/svg/searchglass.svg" alt="search-icon" /></div>
                                    <input value={searchQuery} onChange={(e)=>setSearchQuery(e.target.value)} className='outline-none font-int w-full placeholder:text-[#98A2B3] placeholder:text-[14px] placeholder:font-int px-4' placeholder='Search for Materials' type="text" />
                                    
                                </div>

                            </div>

                            <ul className='overflow-y-scroll h-[300px] pt-2 '>
                                {
                                new_data?.map((i:any, id:any)=>{
                                    return(
                                        <li className='font-int font-[400] text-[14px] py-3' onClick={()=>{handleChange(i.name);setOpen(false)}}>{i.name}</li>
                                    )
                                })                                 
                                }
                            </ul>

                        </div>
                    </div>
                    {/* <div className='w-full relative'> 
                        <p className='font-Rale font-[400] text-[14px] mb-3 text-white'>Items</p>
                        <select value={selected} onChange={handleChange} placeholder='Select your recylable material' className='w-full rounded-lg py-4 md:px-4 outline-none'>


                        {
                            data?.map((i:any, id:any)=>{
                                return(
                                    <option value={i.value}>{i.name}</option>
                                )
                            })
                        }
                            
                        </select>
                    </div> */}
                    <div> 
                        <p className='font-Rale font-[400] text-[14px] mb-3 text-white'>Quantity in KG</p>
                        <input value={amount} onChange={(e)=>setAmount(e.target.value)} placeholder='How many KG do you want to dispose' className='w-full placeholder:font-[300] rounded-lg py-4 px-2 md:px-4 outline-none placeholder:font-int'/>
                    </div>

                    <div className='pt-4 w-full'>
                        <button disabled={!selected?true:false}  type='button' onClick={handleCalculate} className='bg-white rounded-lg border-none w-full py-3 md:px-4 text-[#005700] font-Rale text-[18px] font-[700] font-int md:hover:bg-[#D0DE41] transition-all ease-in-out duration-500'>
                            Calculate
                        </button>                        
                    </div>




                    {
                       minPrice  && <p className='text-[16px] text-[#D0DE41] font-int font-[600] my-4'>Your recycled item worths between: {minPrice} - {maxPrice} naira</p>
                    }
                </form>
            </div>
        
        </div>     

  )
}

export default Calculate