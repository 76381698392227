import React from 'react'
import './../styles.css'

interface FormBoxProps{
    children: React.ReactNode;
    title:string
}

const FormBox = ({children, title}:FormBoxProps) =>{
    return(
        <div className='formbox p-4 rounded-lg'>
            <p className='font-[600] text-[20px] text-[#35393f] pb-6'>{title}</p>
            <div className=''>
                {children}
            </div>
        </div>
    )
}

export default FormBox