import React from "react";

interface FormInputProps {
  label?: string;
  compulsory?:boolean;
  placeholder?: string;
  type: "text" | "select" | "checkbox";
  options?: any[]; // Used for select inputs
  value?: string | boolean |number;
  error?:string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  className?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  compulsory,
  placeholder,
  type,
  options = [],
  value,
  onChange,
  className = "",
  error
}) => {

  return (
    <div className={`mb-4 ${className}`}>
      {label && (
        <label className="block text-sm font-[500] text-[#35393F] mb-1">
          {label} {compulsory && <span style={{color:'red', fontSize:'16px'}}>*</span>}
        </label>
      )}

      {type === "text" && (
        <>
          <input
            type="text"
            placeholder={placeholder}
            value={value as string}
            onChange={onChange}
            className="block w-full placeholder:text-sm h-12 px-4 py-2 border bg-white  border-gray-300 rounded-lg focus:outline-none focus:ring-2  focus:border-transparent"
          />
          {error && <small className="error text-[12px]">{error}</small>}        
        </>

      )}

      {type === "select" && (
        <>
          <select
            value={value as string}
            onChange={onChange}
            className="block w-full border placeholder:text-sm placeholder:text-gray h-12 px-4 py-2 border-[#D0D5DD] rounded-lg focus:outline-none focus:ring-2 focus:border-transparent"
          >
            <option value="" disabled>
              {placeholder}
            </option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option?.text}
              </option>
            ))}
          </select>
          {error && <small className="error text-[12px]">{error}</small>}          
        </>
  
      )}

      {type === "checkbox" && (
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={value as boolean}
            onChange={onChange}
            className="h-3 w-3 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
          />
          {placeholder && (
            <span className="ml-2 text-sm text-gray-700">{placeholder}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormInput;