export const handleResponseMessage = (
  responseMessage: string | Array<string>
) => {
  if (typeof responseMessage === "string") {
    return responseMessage;
  } else {
    return responseMessage.join(", ");
  }
};

export const classes = (...args: string[]) => args.join(" ");

export const chunk = <T = any>(arr: T[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );



  type Option = {
    text: string;
    value: string;
  };
  
  export const formatSelectOptions = (
    obj: any[], 
    text: string = "", 
    value: string = text
  ): Option[] => {
    if (!Array.isArray(obj) || obj.length === 0) return [];
    
    // handles array of objects
    if (text) {
      return obj.map((item) => ({
        text: item[text],
        value: item[value],
      }));
    } else {
      return obj.map((item) => ({
        text: item,
        value: item,
      }));
    }
  };
  
  interface Material {
    aggregatorPrice: string;
    category: string;
    householdPrice: string;
    industrialPrice: string;
  }
  
  interface Pricing {
    name: string;
    price: number;
  }
  
  interface TransformedPricing {
    householdCatPricing: Pricing[];
    aggregatorCatPricing: Pricing[];
    industrialCatPricing: Pricing[];
  }
  
  export const transformMaterialList = (materialList: Material[]): TransformedPricing => {
    return {
      householdCatPricing: materialList.map(item => ({
        name: item.category,
        price: parseFloat(item.householdPrice),
      })),
      aggregatorCatPricing: materialList.map(item => ({
        name: item.category,
        price: parseFloat(item.aggregatorPrice),
      })),
      industrialCatPricing: materialList.map(item => ({
        name: item.category,
        price: parseFloat(item.industrialPrice),
      })),
    };
  };
  