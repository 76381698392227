import { Modal, Box, Typography, Button } from '@mui/material';
import React from 'react';

interface SuccessModalProps {
  open: boolean; 
  onClose: () => void; 
  title?: string; // Optional title for the modal
  message?: string; // Optional message for the modal
}



const ErrorModal: React.FC<SuccessModalProps> = ({ open, onClose, title = "Success", message }) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="success-modal-title" aria-describedby="success-modal-description">
      <Box
        className="bg-white rounded-lg p-6 shadow-lg"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          textAlign: 'center',
        }}
      >
        <div className='flex justify-center'>
          <img src="/img/error.png" className='w-[80px]' alt="mark-icon" />
        </div>
        <Typography className="my-4" id="success-modal-description" variant="body1" gutterBottom>
          {message}
        </Typography>
        <button style={{backgroundColor:'red'}} onClick={onClose}  type='button' className='bg-[red] text-white w-[200px] rounded-3xl p-2'>Continue</button>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
