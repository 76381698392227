import {Routes, Route, createBrowserRouter, RouterProvider} from 'react-router-dom'
import './App.css';
import Home from './pages/home';
import Navbar from './components/header';
import Earn from './pages/products/earn';
import Pay from './pages/products/pay';
import Smart from './pages/products/smart';
import About from './pages/about';
import News from './pages/news';
import BPartner from './pages/become-a-partner';
import Footer from './components/footer/Footer';
import Blog from './pages/blog';
import Publication from './pages/publication';
import Support from './pages/support';
import ContactUs from './pages/contactUs';
import FAQ from './pages/faq';
import Details from './components/support/Details';
import ReviewPage from './pages/reviews';
import Career from './pages/careers';
import Cookies from './pages/cookie';
import EndUser from './pages/endUser';
import Privacy from './pages/privacy';
import TermsAndCondition from './pages/terms';
import Citizens from './pages/serve/Citizens';
import Agencies from './pages/serve/Agencies';
import BusinessOperators from './pages/serve/BusinessOperators';
import AOS from 'aos';
import { useEffect } from 'react';
import Referral from './pages/referral';
import OrganizationOnboarding from './pages/onboarding';


// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Home />,
//   },
//   {
//     path: "/products/earn",
//     element: <Earn />,
//   },
//   {
//     path: "/products/pay",
//     element: <Pay />,
//   },
//   {
//     path: "/products/smart",
//     element: <Smart />,
//   },
//   {
//     path: "/about",
//     element: <About />,
//   },
//   {
//     path: "/news",
//     element: <News />,
//   },
//   {
//     path: "/become-a-partner",
//     element: <BPartner />,
//   },
// ]);

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='w-full lg:screen-xl'>
      
    <Navbar/>
    {/* <RouterProvider router={router} /> */}
      <Routes>
        <Route path='/' element={<Home/>}/>

        <Route path='/reviews' element={<ReviewPage/>}/>
        <Route path='/careers' element={<Career/>}/>
        <Route path='/products/earn' element={<Earn/>}/>
        <Route path='/products/pay' element={<Pay/>}/>
        <Route path='/products/smart' element={<Smart/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/news' element={<News/>}/>
        <Route path='/be-your-own-boss' element={<BPartner/>}/>
        <Route path='/onboarding' element={<OrganizationOnboarding/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/publications' element={<Publication/>}/>
        <Route path='/support' element={<Support/>}/>
        <Route path="/support/details" element={<Details />} />
        <Route path='/contact-us' element={<ContactUs/>}/>
        <Route path='/faqs' element={<FAQ/>}/>
        <Route path='/cookies' element={<Cookies/>}/>
        <Route path='/end-user-policy' element={<EndUser/>}/>
        <Route path='/privacy-policy' element={<Privacy/>}/>
        <Route path='/terms-and-conditions' element={<TermsAndCondition/>}/>
        <Route path='/serve/citizens' element={<Citizens/>}/>
        <Route path='/serve/business-operators' element={<BusinessOperators/>}/>
        <Route path='/serve/agencies' element={<Agencies/>}/>
        <Route path='/referral/:id' element={<Referral/>}/>


      </Routes> 
      <Footer/>   
    </div>

  );
}

export default App;
