import React from 'react'
import { Container } from '../common/AltCard'
import * as fbq from '../../lib/fbpixel';
import axios from 'axios';
import CommentTab from '../common/CommentTab';

const Hero = () => {

  const stagingEnpoint = 'https://stagingapi.pakam.ng/api/facebook/notify'

  
  const handleDownloadAndroid= async() => {
    fbq.event('Download', { device: 'Andriod' })
    
    try {
      const res = await axios.post(stagingEnpoint, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleDownloadIOS= async() => {
    fbq.event('Download', { device: 'IOS' })
    
    try {
      const res = await axios.post(stagingEnpoint, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <div >
      <Container>
        {/* <div className='w-full flex flex-col lg:flex-row  z-[999] pt-[4rem]  lg:pt-[10rem] justify-center items-center  gap-10 lg:gap-0 '>

            <div className='flex-1 h-full space-y-3 flex flex-col  pt-16 lg:pb-20'>
                <h1 style={{color:'rgba(0, 0, 0, 1)'}} className='text-blak text-[35px] font-[750]  sm:text-[42px] md:text-[50px] lg:text-[56px] md:text-4xl md:!leading-12 lg:!leading-[48px] !leading-extra-loose '>Digital marketplace for waste, <span className='text-primary'>recycling,</span> and smart enforcement.</h1>
                
                <p style={{color:'rgba(34, 45, 51, 1)'}} className='font-[500] font-[Raleway] text-[16px] py-4'>Enabling people, planet, and profit.</p>

                <div className='flex space-x-10'>
                    <a  href="https://apps.apple.com/ng/app/pakam-household-recycling-app/id1539296957">
                      <button onClick={handleDownloadIOS}>
                        <img className='h-[60px] w-[200px]' src="/svg/app store badge.svg" alt="" />
                      </button></a>
                    <a  href="https://play.google.com/store/apps/details?id=com.pakamcustomer">
                      <button onClick={handleDownloadAndroid}>
                        <img className='h-[60px] w-[200px]' src="/svg/get-it-on-google-play-badge.svg" alt="" />
                      </button></a>
                </div>
            </div>
            <div className='flex-1 h-full '>
                <img className='w-[550px] md:w-[600px] sm:h-[650px]  lg:h-[500px]' src="/svg/pakam_phone.svg" alt="hero" />
            </div>
          
        </div>    */}


           {/*NEW HERE SECTION  */}

           <div className='w-full h-[100vh] pt-[4rem] lg:pt-[7rem] md:h-[80vh] lg:h-[100vh] flex flex-col justify-center items-center gap-12 md:gap-8 relative'>
              <div className='absolute top-[25%] lg:top-[25%] left-[4%]' data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-in">
                <CommentTab icon='/img/User1.png' title='You Confirmed a Drop-Off request' time='12'/>
              </div>
              <div className='absolute bottom-[15%] md:bottom-[20%] lg:bottom-[25%] right-0' data-aos="fade-left" data-aos-duration="1500" data-aos-easing="ease-in">
                <CommentTab icon='/img/User2.png' title='You have Pet bottle pickup request in Gbagada' time='30'/>
              </div>
              

              <h1 className='text-[#35393F] font-int font-[700] text-[24px] md:text-[48px] lg:text-[52px] text-center leading-[30px] md:leading-[48px] lg:leading-[60px] tracking-[-4%]'><span className='text-[#005A00]'>Recycle Smarter,</span> Not Harder!<br/> <span className='font-[400]'>Earn Rewards with Pakam</span></h1>
              {/* <p className='text-[20px] text-center font-[600] w-[90%] md:w-full'>Effortlessly recycle your waste and get rewarded instantly</p> */}
              <div className='flex space-x-5 md:space-x-10  md:mt-10 ' data-aos="flip-up" data-aos-duration="1500" data-aos-easing="ease-in">
                     <a  href="https://play.google.com/store/apps/details?id=com.pakamcustomer">
                      <button onClick={handleDownloadAndroid}>
                        <img className='md:h-[50px] md:w-[180px] h-[40px] w-[130px]' src="/img/get-it-on-google-play-badge.png" alt="" />
                      </button></a>
                    <a  href="https://apps.apple.com/ng/app/pakam-household-recycling-app/id1539296957">
                      <button onClick={handleDownloadIOS}>
                        <img className='md:h-[50px] md:w-[180px] h-[40px] w-[130px]' src="/img/app store badge.png" alt="" />
                      </button></a>

                </div>
           </div>
      </Container>
    </div>


  )
}

export default Hero
