import React, { useState } from "react";
import { FaChevronCircleDown } from "react-icons/fa";

interface Option {
  text: string;
  value: string;
}

interface MultiSelectProps {
  options: Option[];
  placeholder?: string;
  onChange: (selected: string[]) => void;
  maxVisibleTags?: number;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  placeholder = "Select vehicle...",
  onChange,
  maxVisibleTags = 3,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleOption = (value: string) => {
    const newSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((v) => v !== value)
      : [...selectedOptions, value];

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const isSelected = (value: string) => selectedOptions.includes(value);

  const clearAll = () => {
    setSelectedOptions([]);
    onChange([]);
  };

  return (
    <div className="relative w-full max-w-md">
      {/* Trigger Button */}
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="w-full cursor-pointer rounded border bg-white px-4 py-2 flex items-center justify-between"
      >
        {selectedOptions.length > 0 ? (
          <div className="flex items-center flex-wrap gap-2">
            {selectedOptions.slice(0, maxVisibleTags).map((value) => (
              <div
                key={value}
                style={{
                  backgroundColor:'#005A00',
                  borderRadius:'6px'
                }}
                className="flex items-center gap-1 text-white px-2 py-1 text-sm"
              >
                {value}
                {/* {options.find((option) => option.value === value)?.text} */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOption(value);
                  }}
                  className="text-white hover:text-gray-200"
                >
                  &times;
                </button>
              </div>
            ))}
            {selectedOptions.length > maxVisibleTags && (
              <span className="text-sm text-gray-500">
                +{selectedOptions.length - maxVisibleTags} more
              </span>
            )}
          </div>
        ) : (
          <span className="text-gray-500">{placeholder}</span>
        )}
        <div className="ml-auto flex items-center">
          <button
            type="button"
            style={{
              backgroundColor:'#F5F6F7CC',
              padding:'6px',
              borderRadius:'6px'
            }}
            onClick={(e) => {
              e.stopPropagation();
              clearAll();
            }}
            className="mr-2 text-sm text-gray-500 hover:underline"
          >
            Clear
          </button>
          <span className="ml-2"><FaChevronCircleDown/></span>
          
        </div>
      </div>

      {/* Dropdown */}
      {isDropdownOpen && (
        <div className="absolute z-10 mt-1 w-full max-h-60 overflow-auto rounded border bg-white shadow">
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => toggleOption(option.value)}
              className={`flex items-center px-4 py-2 cursor-pointer ${
                isSelected(option.value) ? "bg-blue-100" : "hover:bg-gray-100"
              }`}
            >
              <input
                type="checkbox"
                checked={isSelected(option.value)}
                onChange={() => {}}
                className="mr-2"
              />
              {option.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
