import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CropperJS from "cropperjs";
import Modal from "react-modal";
import { divProps } from "../../../components/about/SDG";
import { IoClose } from "react-icons/io5";

interface FileUploadProps {
  title: string;
  width: string;
  compulsory?: boolean;
  type: "document" | "picture";
  height: string;
  fieldName: string;
  onFileUpload?: (
    fileData: string | ArrayBuffer | null,
    fieldName: string,
    fileExtension: string
  ) => void;
}

const Modalbody:React.FC<divProps> = ({children})=>{
    return(
        <div className="bg-white pt-[5px] px-[20px] pb-1 min-w-screen md:min-w-[500px] mx-auto min-h-[300px] rounded-[15px]">
            {children}
        </div>
    )
}

const FileUpload: React.FC<FileUploadProps> = ({
  title,
  width,
  height,
  compulsory,
  fieldName,
  type,
  onFileUpload,
}) => {
  const [filePreview, setFilePreview] = useState<string | ArrayBuffer | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [showCropper, setShowCropper] = useState(false);
  const [cropperImage, setCropperImage] = useState<string | null>(null);
  const cropperRef = useRef<CropperJS | null>(null);
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(38,38,38, .75)",
      zIndex: "30",
    },
    content: {
      background: "transparent",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "1px solid #FFFFFF",
      borderRadius: "20px",
      filter: "drop-shadow(0px 2.05834px 6.04394px rgba(215, 228, 249, 0.5))",
      width: "500px",
      height: "350px",
      padding: "12px",
    },  
  };
  const MAX_SIZE_MB = 10;
  const MAX_WIDTH = Number(width);
  const MAX_HEIGHT = Number(height);

  const validateFile = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      if (file.type === "application/pdf") {
        const isValidSize = file.size <= MAX_SIZE_MB * 1024 * 1024;
        if (!isValidSize) {
          alert(`File size exceeds ${MAX_SIZE_MB}MB.`);
          resolve(false);
        } else {
          resolve(true);
        }
      } else if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            const isValidSize = file.size <= MAX_SIZE_MB * 1024 * 1024;
            console.log(typeof img.width, typeof MAX_WIDTH)
            const isValidDimensions =
              img.width <= MAX_WIDTH && img.height <= MAX_HEIGHT;

              if(fieldName !== 'nin'){
                if (!isValidSize) {
                  alert(`File size exceeds ${MAX_SIZE_MB}MB.`);
                  resolve(false);
                } else if (!isValidDimensions) {
                  alert(`Image dimensions cannot be more than ${MAX_WIDTH}x${MAX_HEIGHT}px.`);
                  resolve(false);
                } else {
                  resolve(true);
                }                
              }else{
                resolve(true)
              }

          };
          img.src = reader.result as string;
        };
        reader.readAsDataURL(file);
      } else {
        resolve(false);
      }
    });
  };

  const handleFileUpload = async (file: File | null) => {
    console.log(file && type)
    if (
      file && type === 'picture'
    ) {
      if (file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ){
            const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
            const isValid = await validateFile(file);

            if (isValid) {
              const reader = new FileReader();
              reader.onload = () => {
                if (fieldName === "profileImage" || fieldName === "profileBanner") {
                  setCropperImage(reader.result as string);
                  setShowCropper(true);
                } else {
                  setFilePreview(reader.result);
                  setFileName(file.name);
                  if (onFileUpload) {
                    onFileUpload(reader.result, fieldName, fileExtension);
                  }
                }
              };
              reader.readAsDataURL(file);
            }          
        }else {
          alert("Please upload a valid PNG, JPG, or a JPEG.");
        }


    }
    else if (
      file && type === 'document'
    ) {
      if (file.type === "application/pdf"){
            const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
            const isValid = await validateFile(file);

            if (isValid) {
              const reader = new FileReader();
              reader.onload = () => {

                  setFilePreview(reader.result);
                  setFileName(file.name);
                  if (onFileUpload) {
                    onFileUpload(reader.result, fieldName, fileExtension);

                }
              };
              reader.readAsDataURL(file);
            }          
        }else {
          alert("Please upload a valid PDF File.");
        }
    }
     else {
      alert("Please upload a valid PNG, JPG, JPEG, or PDF file.");
    }
  };

  // const handleFileUpload = async (file: File | null) => {
  //   if (!file || type !== "picture") {
  //     alert("Please upload a valid image.");
  //     return;
  //   }

  //   if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
  //     alert("Please upload a valid PNG, JPG, or JPEG.");
  //     return;
  //   }

  //   const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
  //   const isValid = await validateFile(file);

  //   if (!isValid) return;

  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     console.log(fieldName)
  //     if (fieldName === "profileImage") {
  //       setCropperImage(reader.result as string);
  //       setShowCropper(true);
  //     } else {
  //       setFilePreview(reader.result);
  //       setFileName(file.name);
  //       if (onFileUpload) {
  //         onFileUpload(reader.result, fieldName, fileExtension);
  //       }
  //     }
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleCrop = () => {
    if (!cropperRef.current) return;

    const croppedCanvas = cropperRef.current.getCroppedCanvas();
    if (!croppedCanvas) {
      console.error("Cropping failed: No canvas generated.");
      return;
    }

    croppedCanvas.toBlob((blob) => {
      if (!blob) {
        console.error("Cropping failed: Blob is null.");
        return;
      }

      const croppedUrl = URL.createObjectURL(blob);
      setFilePreview(croppedUrl);
      setFileName("cropped-profile.jpg");

      if (onFileUpload) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            onFileUpload(reader.result, fieldName, "jpg");
          }
        };
        reader.readAsDataURL(blob);
      }

      setShowCropper(false);
    }, "image/jpeg");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    handleFileUpload(file);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);

    const file = event.dataTransfer.files[0];
    handleFileUpload(file);
  };

  return (
    <div className="flex flex-col">
      <p>
        {title} {compulsory && <span style={{ color: "red", fontSize: "16px" }}>*</span>}
      </p>
      <div className="flex flex-col gap-4 md:flex-row py-6 px-2 upload-tab rounded-lg items-center justify-between">
        <div
          className={`flex mb-4 transition-colors w-full ${dragging ? "bg-blue-100" : "bg-transparent"}`}
          onDragOver={(e) => {
            e.preventDefault();
            setDragging(true);
          }}
          onDragLeave={() => setDragging(false)}
          onDrop={(e) => {
            e.preventDefault();
            setDragging(false);
            handleFileUpload(e.dataTransfer.files[0]);
          }}
        >

          {filePreview ? (
            fileName?.endsWith(".pdf") ? (
              <div className="text-gray-500 text-[14px] font-bold">
                {fileName}
              </div>
            ) : (
              <img
              src={filePreview as string}
              alt="Uploaded Preview"
              style={{ width: `${fieldName==='profileImage'?40:width}px`, height: `${fieldName==='profileImage'?40:height}px`, objectFit: "cover" }}
            />
            ) ): (
            <div className="flex flex-col items-center justify-center md:justify-normal w-full md:items-start">
              <p className="text-gray-500 text-[12px] md:text-[14px]">
                <span className="font-bold text-primary">Click to Upload</span> or Drag and drop.
              </p>
              <span className="text-gray-500 text-[12px]"> {type === 'picture' && fieldName !== 'nin' ? `PNG or JPG (${width}x${height}px | max size 10MB)`: fieldName === 'nin' ? `PNG or JPG ` : 'PDF (max size 10MB)'}
                
                </span>
            </div>
          )}
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .pdf"
            onChange={handleFileChange}
            className="hidden"
            id={fieldName}
          />
        </div>
        <label
          htmlFor={fieldName}
          className="inline-block font-bold p-2 text-center bg-primary text-white rounded-md cursor-pointer text-[12px] md:text-[14px] hover:bg-black"
        >
          Browse Files
        </label>
      </div>


      <Modal
        isOpen={showCropper}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}

        style={customStyles}
        contentLabel={fieldName}
      > 
        {/* <div onMouseLeave={closeModal}> */}
            <Modalbody >
            {/* <button className="block ml-auto" onClick={()=>setShowCropper(false)}>
                <div className="rounded-full bg-gray-200 w-[30px] h-[30px] flex justify-center items-center ">
                <IoClose className="text-black" />
                </div>
            </button> */}

              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-4 rounded-lg shadow-lg">
                  <h3 className="text-lg font-bold">Crop Your Profile Image</h3>
                  <Cropper
                    src={cropperImage as string}
                    style={{ height: "100%", width: "100%" }}
                    // aspectRatio={1}
                    // guides={true}
                    viewMode={1}
                    // cropBoxResizable={false} // Prevents manual resizing beyond limits
                    //   cropmove={(event) => {
                    //     const cropper = cropperRef.current;
                    //     if (cropper) {
                    //       const data = cropper.getCropBoxData();

                    //       // Restrict width & height to max 60x60
                    //       if (data.width > 60 || data.height > 60) {
                    //         cropper.setCropBoxData({
                    //           left: data.left,
                    //           top: data.top,
                    //           width: 60,
                    //           height: 60,
                    //         });
                    //       }
                    //     }
                    //   }}
                      onInitialized={(instance) => {
                        cropperRef.current = instance;
                      }}
                    // onInitialized={(instance) => {
                    //   cropperRef.current = instance;
                    // }}
                  />
                  <div className="flex justify-end gap-2 mt-4">
                    <button className="px-4 py-2 bg-gray-300 rounded-md" onClick={() => setShowCropper(false)}>
                      Cancel
                    </button>
                    <button style={{backgroundColor:"#005700"}} className="px-4 py-2  text-white rounded-md" onClick={handleCrop}>
                      Crop & Save
                    </button>
                  </div>
                </div>
              </div>
            </Modalbody>


      </Modal>
    </div>
  );
};

export default FileUpload;
