"use client"

import {Link} from 'react-router-dom';
import React, {useState, useRef, useEffect} from 'react'

interface ListObject {
  icon: string;
  link: string;
  title: string;
}
interface NavButtonProp{
    title: string;
    active: boolean;
    list: ListObject[];
    Click: () => void;
}
const NavButton = ({list, title, active, Click}: NavButtonProp) => {
  const [open, setOpen] = useState(false)
  const btnRef = useRef<HTMLDivElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (menuRef.current && btnRef.current && e.target instanceof Node) {
        if (!menuRef.current.contains(e.target) && !btnRef.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className='relative lg:mr-8 p-8 text-center lg:p-0 w-full'>
      <div 
        onClick={()=>setOpen(!open)} 
        ref={btnRef} style={{color: 'rgba(0, 0, 0, 1)', borderColor:'rgba(0, 0, 0, 1)'}} 
        className={`${title === 'Become a Partner' && '!text-[#005700] font-[800]'} ${active ?  'border-b' : 'border-none'} pb-1 font-[600] text-[16px]`}
      >
        {title}
      </div>

        <div ref={menuRef} className={`absolute left-5 top-25 lg:top-10 flex-col sm:flex-row justify-start lg:justify-between flex-wrap lg:items-center gap-6 bg-[#fff] w-[300px] sm:w-[400px] px-5 py-6 rounded-lg transition-all duration-500 ease-in-out shadow-lg ${open ? 'flex z-[9999999]':'hidden z-[-1]'} `}>
          {
              list.map((item, index)=>(
                <div className='min-w-[45%] hover:font-[700] transition duration-300 ease-in-out'>
                  <Link key={index} to={item.link} >
                    <div  
                      onClick={()=>{
                        Click()
                        setOpen(!open)
                        
                      }}  
                      className="flex items-center space-x-1"
                    >
                      <img src={item.icon} alt="nav icon" className='w-[40px] h-[40px]'/>
                      <p className='text-left text-[14px] font-[600]'>{item.title}</p>
                    </div>
                  </Link>                  
                </div>

              )
                  
              )
          }



        </div>        


    </div>
  )
}

export default NavButton
