import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter  } from 'react-router-dom';
import "./index.css";
import 'aos/dist/aos.css';
import { ToastContainer } from 'react-toastify';

// import 'react-modal/style.css';
// Import the styles from the node_modules/react-modal directory
// import 'react-modal/css/react-modal.css';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      <App />   
    </BrowserRouter>
    {/* <Suspense>
        <App/>
    </Suspense> */}

  </React.StrictMode>
);

